import { defineComponent } from 'vue'

import type { PropType } from 'vue'

export type ScrollToSelfOverride = (
  offset: number,
  container: HTMLElement | null,
) => void

/**
 * @deprecated
 */
export default defineComponent({
  props: {
    /**
     * @deprecated
     */
    pValidity: {
      type: [Boolean, undefined] as PropType<boolean | undefined>,
      required: false,
      default() {
        return undefined
      },
    },
    /**
     * @deprecated
     */
    pIsValidating: {
      type: [Boolean, undefined] as PropType<boolean | undefined>,
      required: false,
      default() {
        return undefined
      },
    },
    /**
     * @deprecated
     */
    pApplyValidity: {
      type: Boolean,
      required: false,
      default() {
        return true
      },
    },
    /**
     * @deprecated
     */
    scrollToSelfOverride: {
      type: Function as PropType<ScrollToSelfOverride | undefined>,
      required: false,
      default: undefined,
    },
    /**
     * @deprecated
     */
    offset: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      /**
       * @deprecated
       */
      PRIVATE_VALIDITY: true,
      /**
       * @deprecated
       */
      PRIVATE_IS_VALIDATING: false,
    }
  },
  computed: {
    /**
     * @deprecated
     */
    validity(): boolean {
      return this.pValidity ?? this.PRIVATE_VALIDITY
    },
    /**
     * @deprecated
     */
    isValidating(): boolean {
      return this.pIsValidating ?? this.PRIVATE_IS_VALIDATING
    },
  },
  methods: {
    /**
     * @deprecated couln't find any usage of this method yet
     */
    scrollToSelf(offset = 0) {
      // @ts-ignore Trust
      const container = window.document.getElementById(this._uid)

      offset = offset || this.offset
      if (typeof this.scrollToSelfOverride === 'undefined') {
        if (container) {
          const containerOffset = container.getBoundingClientRect().top
          const documentOffset = window.document
            .getElementsByTagName('body')[0]
            .getBoundingClientRect().top

          window.scrollTo({
            top: containerOffset - documentOffset - offset,
            left: 0,
            behavior: 'smooth',
          })
        }
      } else {
        this.scrollToSelfOverride(offset, container)
      }
    },
  },
})
